.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.inner-container{
  box-sizing: border-box;
  background-color: white;
  border-radius: 6px;
  padding: 1%;
  overflow: scroll;
  margin-bottom: 3%;
  max-height: 78vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slide{
  transition: all 200ms ease;
  transition-property: left, top;
}
.custom-transforms {
  transition-property: transform;
}

.react-datepicker__month-container {
  /* I know we are not supposed to use !important, but since we are dealing with css nested inside of two different libraries, it is necessary */
  z-index: 99999 !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.rbt-input-main,
.rbt-close.rbt-token-remove-button {
  /* I know we are not supposed to use !important, but since we are dealing with css nested inside of two different libraries, it is necessary */
  z-index: 0 !important;
}

@media only screen and (min-width: 766px){
  .inner-container{
  box-shadow: rgba(0, 0, 0, 0.55) 4px 4px 10px -2px;
  background-color: rgb(250, 250, 250);

  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

