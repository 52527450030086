  .mainCard {
    /* 70px accounts for the navbar */
    height: calc(95vh - 70px);
  }

  .header {
    border-bottom: 1px solid lightgray;
  }

  .ellipsis {
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .change-table-header {
    display: inline-block;
  }

  .change-history-card-item {
    line-height: 2rem;
  }

  .startedTime span {
      position: absolute;
      bottom:0;
  }
  input:checked + p {
    -webkit-line-clamp: unset;
  }

  .change-history-header-title  {
    display:inline-block;
  }

  .change-history-header {
      display: inline-block;
  }

  .change-history-header-info-icon {
    display:inline-block;
    position: absolute;
    right: 2%;
  }

  .change-history-header-icons {
    display: inline-block;
  }

  .change-history-header-icons-top-arrow {
    position: absolute;
    top: -6px;
    display: block;
  }

  .change-history-header-icons-bottom-arrow {
    position: absolute;
    bottom: -6px;
    display: block;
  }

  .change-history-header-icons-refresh {
    position: absolute;
    bottom: 0px;
    right: 1.25rem;
    display: block;
  }

  .change-history-header-icons-card {
    position: absolute;
    bottom: 0px;
    left: -0.5rem;
    display: block;
  }

  .change-history-header-icons-table {
    position: absolute;
    bottom: 0px;
    left: 2.0rem;
    display: block;
  }

  .change-history-header-icon:hover {
    fill: var(--info);
    cursor: pointer;
  }

  .change-history-header-icons-card:hover {
    stroke: var(--info);
  }

  button.btn.change-history-card-status-button.btn-danger {
    opacity: 1;
    background-color: var(--danger);
    border-color: var(--danger);
    color: var(--white);
    cursor: default;
  }

  button.btn.change-history-card-status-button.btn-success {
    opacity: 1;
    background-color: var(--success);
    border-color: var(--success);
    color: var(--white);
    cursor: default;
  }

  button.btn.change-history-card-status-button.btn-warning {
    opacity: 1;
    background-color: var(--warning);
    border-color: var(--warning);
    color: var(--white);
    cursor: default;
}

.data-cy-apply-filters:disabled {
  cursor: not-allowed;
}

.visible {
  visibility: visible;
}
.currentPageButton {
  pointer-events: none
}

.changeHistoryCardSummary {
  font-size: 1.75rem
}

.changeHistoryCardSummary .read-more-button {
  font-size: 1rem
}

.react-datepicker-time__input {
  width : 100% !important; /* This is an internal element within the Datepicker component; !important is necessary to override. */
}

.react-datepicker__close-icon::after {
  background : none !important; /* This is an internal element within the Datepicker component; !important is necessary to override. */
  color      : var(--secondary) !important;
  font-size  : 1rem !important;
  position   : relative;
  top        : -3px;
}

.btn-outline-secondary {
  border-color : #ced4da !important; /* This may be the least invasive way to ensure the form elements on the page match each other */
}

.data-cy-relative-time-unit-dropdown .dropdown-toggle{
  width: 100px; /* This does not appear to be possible through element styling in the Dropdown component */
}

.change-history-table-view .rt-tbody {
  overflow-x: hidden; /* This prevents the table's container from scrolling due to bad padding overflow */
}

.change-history-table-view .rt-td {
  flex-basis: 0 !important; /* This overrides the element-level "auto" flex-basis defined by ReactTable which causes content to be cut off in the rightmost cell */
}
