.paragraph {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: inherit;
  line-height: 13pt;
  margin: 0px 0px 10px;
  color: rgb(102, 101, 100);
}
.header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 14pt;
  margin: 0px 0px 10px;
  color: rgb(0, 0, 0);
  border-bottom: unset;
}
.entity-table {
  margin: 2px;
  width: 468px;
  border: 1px solid;
  border-collapse: collapse;
  font-family: Arial, Arial, Helvetica, sans-serif;
  font-size: 0.80rem;
}
.entity-table tr th {
  padding: 8px 10px;
  text-align: left;
  color: #000;
  background: #f0f0f0;
  font-weight: bold;
  border: 1px solid #B0B0B0;
}
.entity-table tr td {
  padding: 8px 10px;
  text-align: left;
  border: 1px solid #B0B0B0;
  color: rgb(102, 101, 100);
}