
.csv-link {
  position : absolute;
  width    : 168px;
  right    : 0.5rem;
}

.csv-button {
  margin-left : 0.5rem;
  width       : calc(100% - 0.5rem);
  cursor      : pointer;
}

.csv-button:disabled {
  margin-left      : 0.5rem;
  width            : calc(100% - 0.5rem);
  background-color : #BBBBBB !important;
  border-color     : #AAAAAA !important;
  cursor           : not-allowed !important /* Overrides bootstrap .btn pointer */;
}
