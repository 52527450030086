.search-bar{
  max-width: 900px;
  padding: 10px;
  margin: 0 auto;
}

.add-button{
  float: right;
}

.heading{
  display: inline-block;
}
.left-column {
  float: left;
  width: 55%;
  padding: 20px;
}
  
.right-column {
  float: right;
  width: 45%;
  padding: 20px;
}
  
  /* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
  
  /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .left-column,.right-column {
    width: 100%;
  }
}