.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.opi-card-div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.services {
  margin-bottom: 4%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.services>p:last-child {
  padding-bottom: 0 !important;
}

.example-enter {
  opacity: 0.0;
}

.example-enter.example-enter-active {
  transition-delay: .25s;
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.0;
  transition: opacity 100ms ease-in;
}
.landing-container {
  margin-top: 35px;
}

@media only screen and (min-width: 992px) {
  .opi-card-div {
    padding-left: 15px !important;
    padding-right: 0 !important;
  }
}
