.progress-bar-back {
  position: relative;
  height: 100%;
  width: 100%;
  border: none;
  background-color: none;
}

.filler {
  position:relative;
  height: 100%;
  transition: all .75s ease-in;
  border-radius: 6px;
}

.filler-text{
  position: absolute;
  right: 8px;
  top: 12%;
}

.start{
  position: absolute;
  left: 8px;
  top: 12%;
  z-index: 999999;
}

.end{
  position: absolute;
  right: 8px;
  top: 12%;
  z-index: 999999;
}
