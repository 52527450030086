.opi-card {
  padding: 0;
  margin-bottom: 2%;
  color: black;
}

/* .opi-card-link:hover {
  text-decoration: none;
}

.opi-card.card-shadow:hover {
  box-shadow: rgb(0, 143, 211, 0.55) 4px 4px 10px -2px;
} */

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.55) 4px 4px 10px -2px;
}

/* .grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.0075);
}

.card-link {
  color: black;
}

.card-link:hover {
  text-decoration: none;
} */

.inner-col {
  position: relative;
}

.inner-card {
  margin-top: 2%;
}

.inner-card-text {
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-2 {
  padding: 0;
}

.bottom-right {
  position: absolute;
  bottom: 8%;
  right: 2%;
}

.bottom-center {
  position: absolute;
}

.details-container {
  margin-bottom: 2%;
  margin-top: 2%;
}

@media only screen and (min-width: 766px) {
  .bottom-right {
    position: absolute;
    bottom: 10%;
    right: .5%;
  }
}
