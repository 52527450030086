.display-email {
  font-size: 1.15rem;
}

.notification-text {
  font-size: 1.45rem;
}

hr {
  width: 90%;
}

.sap-sub-image {
  max-height: 18rem;
}

@media only screen and (min-width: 814px){
  .notification-text {
    font-size: 2rem;
  }
  .display-email {
    font-size: 1.85rem;
  }
  hr {
    width: 98%;
  }
}

@media only screen and (min-width: 1200px){
  .notification-text {
    font-size: 2.2rem;
  }
  .display-email {
    font-size: 2.15rem;
  }
  hr {
    width: 80%;
  }
}
