@import "./containers/App/72font.css";
body {
  margin: 0;
  padding: 0;
  font-family: '72', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.danger-border-top{
  border-top: 6px solid #dc3545 !important;
}
.okay-border-top{
  border-top: 6px solid #4FB81C !important;
}
.partial-border-top{
  border-top: 6px solid #E35500 !important;
}

.clickable {
  cursor: pointer;
}

.navbar {
  margin-bottom: 2%;
}

#query {
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
form {
  margin-bottom: 2% !important;
}

.nav-right {
  justify-content: flex-end;
}

/* Symbol Styles */
.symbol {
  margin: 0 auto;
  height: 21px;
  width: 21px;
  background-image: url('./assets/ico-symbols.png');
  background-repeat: no-repeat;
}
.symbol.NORMAL,
.symbol.normal {
  background-position: 0 -5px;
}

.symbol.OUTAGE,
.symbol.PERFORMANCE,
.symbol.DISRUPTION,
.symbol.disruption {
  background-position: -28px -5px;
}
.symbol.PARTIAL_OUTAGE,
.symbol.PARTIAL_PERFORMANCE,
.symbol.DEGRADATION,
.symbol.degradation  {
  background-position: -56px -5px;
}

.symbol.NA {
  background-position: -160px -5px;
}

.symbol.US {
  border-radius: 30px;
  background-position: -4px -72px;
}
.symbol.US2 {
  border-radius: 30px;
  background-position: -4px -72px;
}
.symbol.EU {
  border-radius: 30px;
  background-position: -60px -73px;
}
.symbol.EU2 {
  border-radius: 30px;
  background-position: -60px -73px;
}
.symbol.CN {
  border-radius: 30px;
  background-position: -104px -72px;
}

.symbol.USG {
  border-radius: 30px;
  background-position: -4px -72px;
}

.symbol.CONCURGOV {
  border-radius: 30px;
  background-position: -4px -72px;
}

.symbol.CONCURGOVPROD {
  border-radius: 30px;
  background-position: -4px -72px;
}

.symbol.CONCURGOVTEST {
  border-radius: 30px;
  background-position: -4px -72px;
}

.symbol.APJ1 {
  border-radius: 30px;
  background-position: -12px -100px;
}
.symbol.apj1 {
  border-radius: 30px;
  background-position: -12px -100px;
}

.message .message-list {
  margin-bottom: 100px;
}
.message .issue-header {
  font-size: 32px;
  line-height: 34px;
  font-weight: 400;
}
.message .issue-header.PERFORMANCE,
.message .issue-header.PARTIAL_PERFORMANCE {
  color: #FBB040;
}
/* Icon for the header of each message */
.message .issue-header .symbol {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.message .issue-header.PERFORMANCE .symbol {
  background-position: -40px -31px;
}
.message .issue-header.PARTIAL_PERFORMANCE .symbol {
  background-position: -2px -31px;
}

@media (max-width: 600px) {
  .container {
    min-width: 98% !important;
    max-width: 2000px !important;
  }
}

  .container {
    width: 100% !important;
    max-width: 2000px !important;
  }


@media (max-width: 600px) {
  .showing, .filter {
    display: none !important;
  }
  .form-group {
    width: 100%;
  }
}
