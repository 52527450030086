body {
  background-color: whitesmoke
}
.card-body{
  overflow: scroll;
}
.grid {
  display: inline-block;
  width: 800px;
  height: 400px
}
.box {
  display: inline-block;
}
.timeline {
  display: inline-block;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}
.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.coming-soon::after {
  content: "(Coming Soon!)";
  color: #AAAAAA;
  font-size: 0.9rem;
}

.time-line-card{
  /* padding-left: 1% !important; */
  /* overflow: scroll; */
}
.customerInfo {
  /* overflow: scroll; */
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  background-color:	rgb(250,250,254);
  border-radius: 6px;
  border: 1px grey;
  box-shadow: rgba(0, 0, 0, 0.55) 4px 4px 10px -2px;
  padding: 0;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #008FD3;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  z-index: 9999999999;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  z-index: 9999999999;
}

.progress-bar-card{
  padding: 0;
  overflow: hidden;
}
.opi-title{
  font-size: 1.8rem;
  text-align: center;
}
.refresh-time{
  text-align: center !important;
}

@media only screen and (min-width: 992px){
  .opi-title{
    font-size: 1.7rem;
    text-align: left;
    margin-bottom: 0;
  }
  .opi-header{
    margin-bottom: 0;
  }
  .refresh-time{
    text-align: right !important;
    font-size: .75rem;
    margin-bottom: 0 !important;
  }
  .lr-parent{
    position: relative;
  }
  .lr-child{
    position: absolute;
    bottom: -0px !important;
    right: 0;
  }
}
