.elementToFadeIn {
  -webkit-animation: fadein 1s linear;
  animation: fadein 1s linear;
  position: relative;
}

@-webkit-keyframes fadein {
  0%  { height: 0rem; opacity: 0;}
  99% { height: 3rem; opacity: 1;}
}

@keyframes fadein {
  0%  { height: 0rem; opacity: 0;}
  99% { height: 3rem; opacity: 1;}
}


