.navbar {
  width: 90% ;
  max-width: 2000px ;
  margin: 0 auto ;
  margin-bottom: 1% ;
}
.search-bar{
  width: 40vw;
  margin-left: 15px;
}
.base-container{
  display: contents;
}
.logo-box{
  margin-top: 8px;
  font-size: 20px;
}
.dc-container{
  margin-top: 8px;
}
@media (max-width: 769px) {
  .search-bar {
    width: 70vw;
  }
  .dc-container {
    width: 100vw;
    float: right;
    padding-left: 0.5rem;
    font-size: 16px;
    margin-top: unset;
  }

}
@media (min-width: 769px) {
  .base-container {
    display: flex;
  }
}